<template>
  <div class="d-flex flex-column mb-5 align-items-end">
    <div class="d-flex align-items-center">
      <div>
        <a
          href="#"
          class="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
          >Bạn</a
        >
      </div>
      <div class="symbol symbol-circle symbol-40 ml-3">
        <i class="flaticon2-user" style="font-size: 28px"></i>
      </div>
    </div>
    <div
      class="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px"
    >
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['userName', 'content'],
};
</script>
<style lang="scss" scoped></style>
