<template>
  <div
    class="flex-row-auto offcanvas-mobile w-350px w-xl-400px"
    id="kt_chat_aside"
  >
    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body">
        <!--begin:Search-->
        <div class="input-group input-group-solid">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <span class="svg-icon svg-icon-lg">
                <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Search.svg-->
              </span>
            </span>
          </div>
          <input
            type="text"
            class="form-control py-4 h-auto"
            placeholder="Email"
          />
        </div>
        <!--end:Search-->

        <!--begin:Users-->
        <div
          class="mt-7 scroll scroll-pull ps ps--active-y"
          style="height: 424px; overflow: hidden"
        >
          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Matt Pears</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Head of Development</span
                >
              </div>
            </div>

            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >35 mins</span
              >
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Charlie Stone</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Art Director</span
                >
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >7 hrs</span
              >
              <span class="label label-sm label-success">4</span>
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <!--                        original data -->
                <!--                        <img-->
                <!--                          alt="Pic"-->
                <!--                          src="/metronic/theme/html/demo1/dist/assets/media/users/300_10.jpg"-->
                <!--                        />-->
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Teresa Fox</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Web Designer</span
                >
              </div>
            </div>

            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >3 hrs</span
              >
              <span class="label label-sm label-danger">5</span>
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Giannis Nelson</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >IT Consultant</span
                >
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >2 days</span
              >
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Carles Puyol</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Operator</span
                >
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >5 mins</span
              >
              <span class="label label-sm label-success">9</span>
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Ana Torn</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Head Of Finance</span
                >
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >2 days</span
              >
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Lisa Pears</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Web Designer</span
                >
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >6 mins</span
              >
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Amanda Bold</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Art Director</span
                >
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >3 hrs</span
              >
              <span class="label label-sm label-warning">7</span>
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Nick Jhonson</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >IT Consultant</span
                >
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >10 mins</span
              >
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Sarah Larson</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Web Designer</span
                >
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >4 hrs</span
              >
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Matt Pears</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Head of Development</span
                >
              </div>
            </div>

            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >35 mins</span
              >
              <span class="label label-sm label-success">5</span>
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Tim Stone</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Web Developer</span
                >
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >5 hrs</span
              >
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Jhon Richardson</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Head of Development</span
                >
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >1 week</span
              >
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Ana Kiskia</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Web Master</span
                >
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >35 mins</span
              >
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Nick Stone</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Art Director</span
                >
              </div>
            </div>

            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >3 hrs</span
              >
            </div>
          </div>
          <!--end:User-->

          <!--begin:User-->
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-circle symbol-50 mr-3">
                <img alt="Pic" src="" />
              </div>
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                  >Nick Nilson</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Software Arcitect</span
                >
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <span class="text-muted font-weight-bold font-size-sm"
                >3 days</span
              >
            </div>
          </div>
          <!--end:User-->
          <div class="ps__rail-x" style="left: 0px; bottom: 0px">
            <div
              class="ps__thumb-x"
              tabindex="0"
              style="left: 0px; width: 0px"
            ></div>
          </div>
          <div class="ps__rail-y" style="top: 0px; height: 424px; right: -2px">
            <div
              class="ps__thumb-y"
              tabindex="0"
              style="top: 0px; height: 169px"
            ></div>
          </div>
        </div>
        <!--end:Users-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped></style>
