export class MessageProperties {
  /**
   * @param {Object} data
   * @param {String} data.id
   * @param {String} data.content
   * @param {Boolean} data.isBot
   */
  constructor(data) {
    this.id = data.id;
    this.content = data.content;
    this.isBot = data.isBot;
  }
}
