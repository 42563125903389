<template>
  <div>
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <!--begin::Entry-->
      <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container">
          <!--begin::Chat-->
          <div class="d-flex flex-row">
            <!--begin::Aside-->
            <GPTAside v-show="false" />
            <!--end::Aside-->

            <!--begin::Content-->
            <GPTContent v-show="true" />
            <!--end::Content-->
          </div>
          <!--end::Chat-->
        </div>
        <!--end::Container-->
      </div>
      <!--end::Entry-->
    </div>
  </div>
</template>
<script>
import GPTAside from '@/view/components/chat-gpt/GPTAside.vue';
import GPTContent from '@/view/components/chat-gpt/GPTContent.vue';

export default {
  components: { GPTAside, GPTContent },
  data() {
    return {};
  },
  methods: {},
};
</script>
