<template>
  <div class="d-flex flex-column mb-5 align-items-start">
    <div class="d-flex align-items-center">
      <div class="symbol symbol-circle symbol-40 mr-3">
        <img alt="Pic" src="./../../../../assets/image/marvel-gpt.png" />
      </div>
      <div>
        <a
          href="#"
          class="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
          >{{ botName }}</a
        >
      </div>
    </div>
    <div
      class="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left w-100"
    >
      <div>{{ content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['botName', 'message', 'content'],
  watch: {},
};
</script>
<style lang="scss" scoped></style>
