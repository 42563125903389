<template>
  <div class="flex-row-fluid ml-lg-8" id="kt_chat_content">
    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Header-->
      <div v-show="true" class="card-header align-items-center px-4 py-3">
        <div class="text-left flex-grow-1">
          <!--begin::Aside Mobile Toggle-->
          <!--          <button-->
          <!--            type="button"-->
          <!--            class="btn btn-clean btn-sm btn-icon btn-icon-md d-lg-none"-->
          <!--            id="kt_app_chat_toggle"-->
          <!--          >-->
          <!--            <span class="svg-icon svg-icon-lg">-->
          <!--              &lt;!&ndash;begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Communication/Adress-book2.svg&ndash;&gt;-->

          <!--              &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
          <!--            </span>-->
          <!--          </button>-->
          <!--end::Aside Mobile Toggle-->

          <!--begin::Dropdown Menu-->
          <!--          <div class="dropdown dropdown-inline">-->
          <!--            <button-->
          <!--              type="button"-->
          <!--              class="btn btn-clean btn-sm btn-icon btn-icon-md"-->
          <!--              data-toggle="dropdown"-->
          <!--              aria-haspopup="true"-->
          <!--              aria-expanded="false"-->
          <!--            >-->
          <!--              <i class="ki ki-bold-more-hor icon-md"></i>-->
          <!--            </button>-->
          <!--            <div-->
          <!--              class="dropdown-menu p-0 m-0 dropdown-menu-left dropdown-menu-md"-->
          <!--            >-->
          <!--              &lt;!&ndash;begin::Navigation&ndash;&gt;-->
          <!--              <ul class="navi navi-hover py-5">-->
          <!--                <li class="navi-item">-->
          <!--                  <a href="#" class="navi-link">-->
          <!--                    <span class="navi-icon"-->
          <!--                      ><i class="flaticon2-drop"></i-->
          <!--                    ></span>-->
          <!--                    <span class="navi-text">New Group</span>-->
          <!--                  </a>-->
          <!--                </li>-->
          <!--                <li class="navi-item">-->
          <!--                  <a href="#" class="navi-link">-->
          <!--                    <span class="navi-icon"-->
          <!--                      ><i class="flaticon2-list-3"></i-->
          <!--                    ></span>-->
          <!--                    <span class="navi-text">Contacts</span>-->
          <!--                  </a>-->
          <!--                </li>-->
          <!--                <li class="navi-item">-->
          <!--                  <a href="#" class="navi-link">-->
          <!--                    <span class="navi-icon"-->
          <!--                      ><i class="flaticon2-rocket-1"></i-->
          <!--                    ></span>-->
          <!--                    <span class="navi-text">Groups</span>-->
          <!--                    <span class="navi-link-badge">-->
          <!--                      <span-->
          <!--                        class="label label-light-primary label-inline font-weight-bold"-->
          <!--                        >new</span-->
          <!--                      >-->
          <!--                    </span>-->
          <!--                  </a>-->
          <!--                </li>-->
          <!--                <li class="navi-item">-->
          <!--                  <a href="#" class="navi-link">-->
          <!--                    <span class="navi-icon"-->
          <!--                      ><i class="flaticon2-bell-2"></i-->
          <!--                    ></span>-->
          <!--                    <span class="navi-text">Calls</span>-->
          <!--                  </a>-->
          <!--                </li>-->
          <!--                <li class="navi-item">-->
          <!--                  <a href="#" class="navi-link">-->
          <!--                    <span class="navi-icon"-->
          <!--                      ><i class="flaticon2-gear"></i-->
          <!--                    ></span>-->
          <!--                    <span class="navi-text">Settings</span>-->
          <!--                  </a>-->
          <!--                </li>-->

          <!--                <li class="navi-separator my-3"></li>-->

          <!--                <li class="navi-item">-->
          <!--                  <a href="#" class="navi-link">-->
          <!--                    <span class="navi-icon"-->
          <!--                      ><i class="flaticon2-magnifier-tool"></i-->
          <!--                    ></span>-->
          <!--                    <span class="navi-text">Help</span>-->
          <!--                  </a>-->
          <!--                </li>-->
          <!--                <li class="navi-item">-->
          <!--                  <a href="#" class="navi-link">-->
          <!--                    <span class="navi-icon"-->
          <!--                      ><i class="flaticon2-bell-2"></i-->
          <!--                    ></span>-->
          <!--                    <span class="navi-text">Privacy</span>-->
          <!--                    <span class="navi-link-badge">-->
          <!--                      <span-->
          <!--                        class="label label-light-danger label-rounded font-weight-bold"-->
          <!--                        >5</span-->
          <!--                      >-->
          <!--                    </span>-->
          <!--                  </a>-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--              &lt;!&ndash;end::Navigation&ndash;&gt;-->
          <!--            </div>-->
          <!--          </div>-->
          <!--end::Dropdown Menu-->
        </div>
        <div class="text-center flex-grow-1">
          <div class="text-dark-75 font-weight-bold font-size-h5">
            {{ botName }}
          </div>
          <!--          <div>-->
          <!--            <span class="label label-sm label-dot label-success"></span>-->
          <!--            <span class="font-weight-bold text-muted font-size-sm">Active</span>-->
          <!--          </div>-->
        </div>
        <div class="text-right flex-grow-1">
          <!--begin::Dropdown Menu-->
          <div class="dropdown dropdown-inline">
            <button
              type="button"
              class="btn btn-clean btn-sm btn-icon btn-icon-md"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="svg-icon svg-icon-lg">
                <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Communication/Add-user.svg-->
                <!--end::Svg Icon-->
              </span>
            </button>
            <div
              class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-md"
            >
              <!--begin::Navigation-->
              <ul class="navi navi-hover py-5">
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-icon"
                      ><i class="flaticon2-drop"></i
                    ></span>
                    <span class="navi-text">New Group</span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-icon"
                      ><i class="flaticon2-list-3"></i
                    ></span>
                    <span class="navi-text">Contacts</span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-icon"
                      ><i class="flaticon2-rocket-1"></i
                    ></span>
                    <span class="navi-text">Groups</span>
                    <span class="navi-link-badge">
                      <span
                        class="label label-light-primary label-inline font-weight-bold"
                        >new</span
                      >
                    </span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-icon"
                      ><i class="flaticon2-bell-2"></i
                    ></span>
                    <span class="navi-text">Calls</span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-icon"
                      ><i class="flaticon2-gear"></i
                    ></span>
                    <span class="navi-text">Settings</span>
                  </a>
                </li>

                <li class="navi-separator my-3"></li>

                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-icon"
                      ><i class="flaticon2-magnifier-tool"></i
                    ></span>
                    <span class="navi-text">Help</span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-icon"
                      ><i class="flaticon2-bell-2"></i
                    ></span>
                    <span class="navi-text">Privacy</span>
                    <span class="navi-link-badge">
                      <span
                        class="label label-light-danger label-rounded font-weight-bold"
                        >5</span
                      >
                    </span>
                  </a>
                </li>
              </ul>
              <!--end::Navigation-->
            </div>
          </div>
          <!--end::Dropdown Menu-->
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body" ref="chat-content">
        <!--begin::Scroll-->
        <perfect-scrollbar
          class="offcanvas-content scroll"
          style="height: 424px; overflow: hidden"
        >
          <div
            v-if="!messages || !messages.length"
            style="
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <div>
              <i class="fas fa-arrow-down icon-10x"></i>
            </div>
          </div>
          <!--begin::Messages-->
          <div class="messages" style="position: relative">
            <div v-for="(message, index) in messages" :key="messages[index].id">
              <MessageOut
                v-if="!message.isBot && message.content"
                :content="message.content"
              />
              <MessageIn
                v-if="message.isBot"
                :bot-name="botName"
                :content="message.content"
              />
            </div>
            <div ref="bottomEl"></div>
          </div>
          <!--end::Messages-->
          <div class="ps__rail-x" style="left: 0px; bottom: 0px">
            <div
              class="ps__thumb-x"
              tabindex="0"
              style="left: 0px; width: 0px"
            ></div>
          </div>
          <div class="ps__rail-y" style="top: 0px; height: 275px; right: -2px">
            <div
              class="ps__thumb-y"
              tabindex="0"
              style="top: 0px; height: 71px"
            ></div>
          </div>
        </perfect-scrollbar>
      </div>

      <!--end::Scroll-->
    </div>
    <!--end::Body-->

    <!--begin::Footer-->
    <div class="card-footer align-items-center">
      <!--begin::Compose-->
      <b-form-input
        class="form-control border-1 pl-2"
        rows="2"
        :placeholder="`Nhắn tin với ${botName}`"
        v-model="clientMessage"
        v-on:keydown.enter="sendMessage('chat-content')"
      ></b-form-input>
      <div class="d-flex align-items-center justify-content-between mt-5">
        <div class="mr-3">
          <!--          <a href="#" class="btn btn-clean btn-icon btn-md mr-1"-->
          <!--          ><i class="flaticon2-photograph icon-lg"></i-->
          <!--          ></a>-->
          <!--          <a href="#" class="btn btn-clean btn-icon btn-md"-->
          <!--          ><i class="flaticon2-photo-camera icon-lg"></i-->
          <!--          ></a>-->
        </div>
        <div>
          <button
            type="button"
            class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6"
            @click.enter="sendMessage('chat-content')"
            :disabled="!!isBusy"
          >
            Gửi
          </button>
        </div>
      </div>
      <div class="text-center mt-4">
        <p style="color: #a9a9ac">
          {{ botName }} có thể mắc sai lầm. Kết quả chỉ mang tính chất tham
          khảo. Hãy kiểm tra lại các thông tin quan trọng.
        </p>
      </div>
      <!--begin::Compose-->
    </div>
    <!--end::Footer-->
  </div>
  <!--end::Card-->
</template>
<script>
import MessageIn from '@/view/components/chat-gpt/components/MessageIn.vue';
import MessageOut from '@/view/components/chat-gpt/components/MessageOut.vue';
import { MessageProperties } from '@/view/components/chat-gpt/utils/MessageProperties';
import { v4 } from 'uuid';
import { makeToastFaile } from '@/utils/common';

export default {
  components: { MessageIn, MessageOut },
  data() {
    return {
      botName: 'ChatBI',
      isBusy: false,
      clientMessage: '',
      tmpClientMessage: '',
      messages: [],
    };
  },
  methods: {
    async sendMessage() {
      this.isBusy = true;
      this.tmpClientMessage = this.clientMessage.trim() || '';
      this.clientMessage = '';

      const humanMessage = Object.assign(
        {},
        new MessageProperties({
          id: v4(),
          content: this.tmpClientMessage.trim() || '',
          isBot: false,
        }),
      );
      this.messages.push(humanMessage);
      this.$nextTick(() => {
        this.scrollToLastMessage();
      });

      try {
        const response = await fetch('http://localhost:3018/api/gpt/ask', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            question: this.tmpClientMessage.trim() || '',
          }),
        });

        if (!response.body) {
          this.handleError();
        }

        this.resetData();
        const aiMessage = this.createAiMessage();
        this.$nextTick(() => {
          this.scrollToLastMessage();
        });
        const { reader, processChunk } = this.processChunk(response, aiMessage);

        reader.read().then(processChunk);
        this.$nextTick(() => {
          this.scrollToLastMessage();
        });
      } catch (error) {
        this.handleError(error);
      }
    },
    resetData() {
      this.clientMessage = '';
      this.tmpClientMessage = '';
    },
    scrollToLastMessage() {
      this.$refs.bottomEl?.scrollIntoView({ behavior: 'smooth' });
    },
    processChunk(response, aiMessage) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      const processChunk = ({ done, value }) => {
        if (done) {
          this.isBusy = false;
          this.$nextTick(() => {
            this.scrollToLastMessage();
          });
          return;
        }

        const chunks = decoder.decode(value, { stream: true });
        aiMessage.content += chunks;

        this.$forceUpdate();

        return reader.read().then(processChunk);
      };
      return { reader, processChunk };
    },
    createAiMessage() {
      const aiMessage = Object.assign(
        {},
        new MessageProperties({
          id: v4(),
          content: '',
          isBot: true,
        }),
      );
      this.messages.push(aiMessage);
      return aiMessage;
    },
    handleError(error) {
      this.isBusy = false;
      this.$nprogress.done();
      if (error.response) {
        makeToastFaile(
          error.response.data ? error.response.data.message : 'Lỗi',
        );
      } else {
        makeToastFaile(error.message || 'Lỗi');
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
